import { PlusOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Space, Tag, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCreatorFooters, getCreatorHomeSubFooters, getFanFooters } from "../../helpers/constant";
import { authService, userService } from "../../services";
import FooterItemComponent from "../FooterItemComponent";
import FormationModalComponent from "../Formations/Creators/Modals";
import LingoImg from "../../assets/images/lingot.webp";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";

type props = {
  type?: string;
  page: string;
};

const useClickOutside = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, callback]);
};

const FooterComponent: React.FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCreatorHomeOpen, setIsCreatorHomeOpen] = useState<boolean>(false);
  const type = authService.getRole();
  const fanFooters = getFanFooters(t);
  const creatorFooters = getCreatorFooters(t);
  const creatorHomeSubFooters = getCreatorHomeSubFooters(t);
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);
  const subMenuRef = useRef<HTMLDivElement>(null);
  const [isSlidingDown, setIsSlidingDown] = useState<boolean>(false);
  const [formationModalIsVisible, setFormationModalIsVisible] = useState<boolean>(false);
  const [formationModalName, setFormationModalName] = useState<string>("");
  const [fieldName, setFieldName] = useState<string>("");
  const [subFooterLink, setSubFooterLink] = useState<string>("");
  const [modaleFormationMenu, setModalFormationMenu] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMenuClick = async () => {
    setIsCreatorHomeOpen(!isCreatorHomeOpen);
    setIsOverlayVisible(!isOverlayVisible);

    if (!isCreatorHomeOpen) {
      const storedValue = localStorage.getItem("hasSeenMenuExplanationModal");

      if (storedValue === null) {
        setIsLoading(true);
        const data = await userService.getModalStatus("hasSeenMenuExplanationModal");
        localStorage.setItem("hasSeenMenuExplanationModal", JSON.stringify(data.value));

        if (!data.value) {
          setFieldName("hasSeenMenuExplanationModal");
          setModalFormationMenu(true);
        }
        setIsLoading(false);
      } else {
        const hasSeenModal = JSON.parse(storedValue);
        if (!hasSeenModal) {
          setFieldName("hasSeenMenuExplanationModal");
          setModalFormationMenu(true);
        }
      }
    }
  };

  const handleOverlayClick = () => {
    setIsCreatorHomeOpen(false);
    setIsOverlayVisible(false);
  };

  useClickOutside(subMenuRef, handleOverlayClick);

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleSlideDown = () => {
    setIsSlidingDown(true);
    setTimeout(() => {
      setIsCreatorHomeOpen(false);
      setIsOverlayVisible(false);
      setIsSlidingDown(false);
    }, 300);
  };

  const openFormationModal = async (subFooterId: string, navigateTo: string) => {
    let tempFieldName = "";
    let modalName = "";

    switch (subFooterId) {
      case "Collection":
        tempFieldName = "hasSeenCollectionModal";
        modalName = "collection";
        break;
      case "Push":
        tempFieldName = "hasSeenMediaPushModal";
        modalName = "media-push";
        break;
      case "Unlock":
        tempFieldName = "hasSeenUnlockModal";
        modalName = "unlock";
        break;
      case "Live":
        tempFieldName = "hasSeenLiveModal";
        modalName = "live";
        break;
      case "Post":
        tempFieldName = "hasSeenPostModal";
        modalName = "profile";
        break;
      default:
        break;
    }
    setFormationModalName(modalName);
    setFieldName(tempFieldName);

    if (localStorage.getItem(tempFieldName) === null || !localStorage.getItem(tempFieldName)) {
      setIsLoading(true);
      localStorage.setItem(tempFieldName, JSON.stringify(true));
      await userService
        .getModalStatus(tempFieldName)
        .then((data) => {
          if (data.value) {
            navigate(navigateTo);
          } else {
            setIsCreatorHomeOpen(false);
            setIsOverlayVisible(false);
            setFormationModalIsVisible(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      navigate(navigateTo);
    }
  };

  const getIt = async () => {
    setIsLoading(true);

    await userService
      .updateModalStatus(fieldName)
      .then(() => {
        localStorage.setItem(fieldName, JSON.stringify(true));
        if (fieldName === "hasSeenMenuExplanationModal") {
          setModalFormationMenu(false);
        } else {
          setFormationModalIsVisible(false);
          navigate(subFooterLink);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const Loader: React.FC = () => (
    <div className="w-full d-flex justify-content-center mt-40 mb-40">
      <SpinnerComponent />
    </div>
  );

  return (
    <Layout.Footer className="footer">
      {type === "fan" &&
        fanFooters.map((footer) => {
          return (
            <FooterItemComponent
              key={footer.id}
              id={footer.id}
              name={footer.name}
              link={footer.link}
              icon={footer.icon}
              activeIcon={footer.activeIcon}
              isActive={page === footer.id}
            />
          );
        })}
      {type === "creator" &&
        creatorFooters.map((footer) => {
          if (footer.name === "Home") {
            return (
              <div className="creatorHomeFooterItemWrapper" key={footer.id} ref={subMenuRef}>
                <Tag className="creatorHomeFooterItem" onClick={handleMenuClick}>
                  <PlusOutlined style={{ color: "#000", fontSize: 26 }} />
                </Tag>
                {isOverlayVisible && <div className="items-overlay" onClick={handleOverlayClick}></div>}
                {isCreatorHomeOpen && (
                  <div className="footer-modal-overlay no-scroll" onClick={modaleFormationMenu ? () => {} : handleSlideDown}>
                    {modaleFormationMenu ? (
                      <div className={`footer-modal-menu-formation-content ${isSlidingDown ? "close-modal" : ""}`} onClick={handleModalClick}>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <Row className="w-full justify-content-center" onClick={handleSlideDown}>
                              <div className="modal-line-header"></div>
                            </Row>
                            <div className="formation-menu-content">
                              <Typography.Text className="formation-menu-title">{t("formation-sub-menu-title")}</Typography.Text>
                              <Typography.Text className="formation-menu-desc">{t("formation-sub-menu-desc")}</Typography.Text>
                              <div className="formation-menu-img-container">
                                <img src={LingoImg} className="formation-menu-img" alt="menu" />
                              </div>
                            </div>
                            <button className="formation-menu-button" onClick={getIt}>
                              {t("i-understand")}
                            </button>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={`footer-modal-content ${isSlidingDown ? "close-modal" : ""}`} onClick={handleModalClick}>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <div>
                            <Row className="w-full justify-content-center" onClick={handleSlideDown}>
                              <div className="modal-line-header"></div>
                            </Row>
                            <Typography.Text className="font-20-bold text-white-color mt-20">{t("what-do-you-want-to-do")}</Typography.Text>
                            <div className="sub-menu-content mt-10">
                              {creatorHomeSubFooters.map((subFooter) => {
                                return (
                                  <Row
                                    key={subFooter.id}
                                    align="middle"
                                    className="sub-menu-row"
                                    onClick={() => {
                                      setSubFooterLink(subFooter.link);
                                      openFormationModal(subFooter.id, subFooter.link);
                                    }}
                                  >
                                    <img className="footer-icon-img" src={subFooter.icon} alt="sub footer" />
                                    <Col>
                                      <Row>
                                        <Typography.Text className="sub-menu-title">{subFooter.name}</Typography.Text>
                                      </Row>
                                      <Row>
                                        <Typography.Text className="sub-menu-description">{subFooter.description}</Typography.Text>
                                      </Row>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <FooterItemComponent
                key={footer.id}
                id={footer.id}
                name={footer.name}
                link={footer.link || ""}
                icon={footer.icon || ""}
                activeIcon={footer.activeIcon || ""}
                isActive={page === footer.id}
              />
            );
          }
        })}

      {formationModalName !== "" && (
        <FormationModalComponent
          id={formationModalName}
          visible={formationModalIsVisible}
          onClose={() => {
            setFormationModalIsVisible(false);
          }}
          getIt={getIt}
        />
      )}
    </Layout.Footer>
  );
};

export default FooterComponent;
