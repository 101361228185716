import { Button, message, Modal, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteAlbum } from "../../redux/quickFile/quickFileSlice";
import { AppDispatch } from "../../redux/store";
import { quickFileService } from "../../services/quickFile.service";
import "./index.less";
import { deleteQuickMessage } from "../../redux/messages/quickMessagesSlice";

type props = {
  isOpen: boolean;
  onClose: () => void;
  messageId: string;
};

const CreatorDeleteQuickMessageModal: React.FC<props> = ({ isOpen, onClose, messageId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const deleteQuickAnswer = () => {
    dispatch(deleteQuickMessage(messageId));
    onClose();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-26">{t("Do-you-really-want-delete-a-message")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <button className="bottom-modal-button" onClick={deleteQuickAnswer}>
            <Typography.Text className="bottom-modal-button-title">{t("Yes-delete")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatorDeleteQuickMessageModal;
