import axios from "axios";
import { ICountry } from "../helpers/types";
import { COUNTRIES } from "../utls/CountryUtil";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

class UtilService {
  public getPhoneCoutries = () => {
    return new Promise<ICountry[]>((resolve) => {
      resolve(COUNTRIES);
    });
    /* const url = `/countries/list`;
    return new Promise<ICountry[]>((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    }); */
  };

  public fetchUserCountry = async () => {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(`https://ipapi.co/country_name/`)
        .then((response) => {
          if (response?.data) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public fetchClientDetails = async () => {
    try {
      // Fetch IP Address
      const ipResponse = await axios.get("https://ipapi.co/json/");
      const IPAddress = ipResponse?.data?.ip;


      // Fetch Fingerprint
      const fp = await FingerprintJS.load();
      const fingerPrint = (await fp.get()).visitorId;

      // Get User-Agent
      const userAgent = navigator.userAgent;

      return {
        IPAddress,
        fingerPrint,
        userAgent,
      };
    } catch (error) {
      console.error("Error fetching client details:", error);
      throw new Error("Failed to fetch client details");
    }
  };

}

export const utilService = new UtilService();
