import { Button, Col, Layout, Row, Typography } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ChevronBackIcon from "../../../assets/icons/chevron-back-white.svg";
import ChevronLeftIcon from "../../../assets/icons/chevron-left-collection.svg";
import ChevronRightIcon from "../../../assets/icons/chevron-right-collection.svg";
import VideoIcon from "../../../assets/icons/jouer.svg";
import MediaComponent from "../../../components/MediaComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { PAYMENT_TYPES } from "../../../helpers/constant";
import { IFanCollection } from "../../../helpers/types";
import { collectionService } from "../../../services/collection.service";
import "./index.less";

const CollectionPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId, username } = useParams();
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [collectionData, setCollectionData] = useState<IFanCollection | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayPrivateImage, setDisplayPrivateImage] = useState(false);

  useEffect(() => {
    if (collectionId) {
      setIsLoading(true);
      collectionService
        .getFanCollectionDetail(collectionId)
        .then((data: IFanCollection) => {
          data.files = data.files.sort((a, b) => Number(b.isThumbnail) - Number(a.isThumbnail));
          setCollectionData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("error:", error);
          setIsLoading(false);
        });
    }
  }, [collectionId]);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [collectionId]);

  const handleNext = () => {
    setCurrentFileIndex((prevIndex) => {
      if (collectionData && prevIndex < collectionData.files.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  };

  const handlePrev = () => {
    setCurrentFileIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1;
      }
      return prevIndex;
    });
  };

  const handlePayForCollection = () => {
    navigate(
      `/common/payment?creatorId=${collectionData?.creatorId}&type=${PAYMENT_TYPES.COLLECTION}&collectionId=${collectionData?._id}&redirectUrl=common/collection/${collectionData?._id}/${username}`
    );
  };

  const handleSubscribeToCreator = () => {
    navigate(
      `/common/subscribe/${collectionData?.creatorId}?isFromCollection=true&redirectUrl=/common/collection/${collectionData?._id}/${username}`
    );
  };

  const handlePaymentOrSubscription = () => {
    if (displayPrivateImage) {
      setDisplayPrivateImage(false);
    }
    if (collectionData?.isSubscribed) {
      handlePayForCollection();
    } else {
      handleSubscribeToCreator();
    }
  };

  const handleNavigate = () => {
    navigate(`/p/${collectionData?.username}?ctc=collections`);
  };

  const privateImageClick = () => {
    setDisplayPrivateImage(true);
  };

  const currentFile = collectionData?.files[currentFileIndex];

  return (
    <Layout className="gradient-background-profile">
      <Layout.Content className="fan-collection-page-wrapper background-transparent">
        <Row className="collection-header">
          <img src={ChevronBackIcon} width={10} onClick={handleNavigate} />
          {/* <img src={OptionIcon} width={24} onClick={() => {}} /> */}
        </Row>
        {isLoading ? (
          <Row className="w-full justify-content-center mt-20">
            <SpinnerComponent />
          </Row>
        ) : (
          <>
            <Row className="mt-20 justofy-content-center">
              <Typography.Text className="collection-title-text">{collectionData?.title}</Typography.Text>
            </Row>
            <Row className="mt-10">
              <Typography.Text className="collection-price-text">{`${t("for-1")} ${collectionData?.price ? collectionData.price.toFixed(2) : "0.00"} €`}</Typography.Text>
            </Row>
            <div className="img-container">
              <div
                className="image-current-wrapper"
                onClick={(e) => {
                  if (currentFile?.isPrivate && !collectionData?.isPaymentVerified) {
                    privateImageClick();
                    e.stopPropagation();
                  }
                }}
              >
                <MediaComponent
                  key={currentFile?.url}
                  width={340}
                  height={320}
                  borderRadius={11}
                  image={currentFile?.type.includes("video") ? currentFile.thumbnailUrl! : currentFile?.url!}
                  isPrivate={currentFile?.isPrivate && !collectionData?.isPaymentVerified}
                  isVideo={currentFile?.type.includes("video")}
                  url={currentFile?.url}
                  shouldAddWatermark={true}
                  fromCollection={true}
                />
              </div>
            </div>

            <Row className="img-switch-container">
              {currentFileIndex > 0 ? (
                <img src={ChevronLeftIcon} width={26} alt="left" onClick={handlePrev} />
              ) : (
                <div style={{ width: "24px" }}></div>
              )}

              <div className="preview-container">
                {[currentFileIndex - 2, currentFileIndex - 1, currentFileIndex, currentFileIndex + 1, currentFileIndex + 2].map((index, i) => {
                  const file = collectionData?.files[index];
                  const isActive = index === currentFileIndex;

                  // Skip rendering if no file exists at the index
                  if (!file) return null;

                  return (
                    <div key={index} className={`preview-wrapper ${isActive ? "active" : ""}`}>
                      <img
                        src={file?.type.includes("video") ? file?.thumbnailUrl : file.url}
                        width={isActive ? 100 : 80}
                        alt={isActive ? "current" : "preview"}
                        className="preview-image"
                        onClick={() => {
                          if (index < currentFileIndex) handlePrev();
                          else if (index > currentFileIndex) handleNext();
                        }}
                      />
                      {file.type.includes("video") && <img src={VideoIcon} className="video-icon" alt="video-icon" />}
                    </div>
                  );
                })}
              </div>

              {currentFileIndex < collectionData?.files.length! - 1 ? (
                <img src={ChevronRightIcon} width={26} alt="right" onClick={handleNext} />
              ) : (
                <div style={{ width: "24px" }}></div>
              )}
            </Row>

            <Row className="mt-20 resume-container">
              <Typography.Text className="description-text">{collectionData?.description}</Typography.Text>
            </Row>
            <Row className="mt-20 collection-content-container">
              <Col className="collection-content-col">
                <Typography.Text className="collection-content-title">{t("collection")}</Typography.Text>
                <Typography.Text className="collection-content-text">{t("what-does-it-contain")}</Typography.Text>
              </Col>
              <Col className="collection-content-col">
                <Typography.Text className="collection-content-text">
                  {collectionData?.imagesCount} {collectionData?.imagesCount && collectionData.imagesCount > 1 ? t("photos") : t("photo")} &{" "}
                  {collectionData?.videoCount} {collectionData?.videoCount && collectionData.videoCount > 1 ? t("videos") : t("video")}
                </Typography.Text>
              </Col>
            </Row>
            {!collectionData?.isPaymentVerified && (
              <Row className="collection-btn-sticky-row">
                <Button className="collection-sticky-btn" onClick={handlePaymentOrSubscription}>
                  {t("pay-to-see")}
                </Button>
              </Row>
            )}
          </>
        )}

        {displayPrivateImage && (
          <div className="private-image-overlay" onClick={() => setDisplayPrivateImage(false)}>
            <div className="private-content-wrapper">
              <img
                className="private-large-image"
                src={currentFile?.type.includes("video") ? currentFile.thumbnailUrl! : currentFile?.url!}
                alt="Avatar en grand"
              />
              <div className="private-text-container">
                {/* <Row>
                  <Typography.Text className="reserved-title">{t("reserved-for-subscribers")}</Typography.Text>
                </Row>
                <Row>
                  <Typography.Text className="reserved-description">{t("reserved-for-subscribers-description")}</Typography.Text>
                </Row> */}
                <Button onClick={handlePaymentOrSubscription} type="link" className="pay-to-see-btn">
                  {t("unlocking-collection")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default CollectionPage;
