import { Badge, message, Row, Space, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONVERSATION_TYPES, CREATOR, REQUEST_LOADING_STATES } from "../../helpers/constant";
import { IConversation } from "../../helpers/types";
import { getUserConversations, resetUserConversations, selectConversation } from "../../redux/messages/messagesSlice";
import type { AppDispatch, StoreState } from "../../redux/store";
import { authService } from "../../services";
import ConversationAvatarComponent from "../ConversationAvatarComponent";
import ConversationOnlineStatusComponent from "../ConversationOnlineStatusComponent";
import CountDownComponent from "../CountDownComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import VerifiedUserIconComponent from "../VerifiedUserIconComponent/VerifiyedUserIconComponent";
import UnreadIcon from "../../assets/icons/unread-chat.svg";
import UnreadActiveIcon from "../../assets/icons/unread-chat-active.svg";
import "./index.less";

type props = {
  user: string;
  type: string;
  activeKey?: string;
};

const MessageTabListComponent: React.FC<props> = ({ user, type, activeKey = CONVERSATION_TYPES.SUBSCRIPTION }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { searchConversations, loading, error } = useSelector((state: StoreState) => state.messages);
  const conversationsData = useSelector((state: StoreState) => state.messages.conversationsData.find((conversation) => conversation.type === type));
  const shouldFetch = useRef(true);
  const page = conversationsData?.page || 1;
  const hasNextPage = (conversationsData && conversationsData.data.length > 0 && conversationsData?.page < conversationsData?.totalPages) || false;
  // const [unreadFilter, setUnreadFilter] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetUserConversations());
    if (shouldFetch.current && type !== CONVERSATION_TYPES.SEARCH) {
      shouldFetch.current = false;
      if (!conversationsData) {
        dispatch(getUserConversations({ page, type }));
      }
    }
  }, []);

  useEffect(() => {
    if (loading === REQUEST_LOADING_STATES.FAILED && error) {
      message.error("Something went wrong.");
    }
  }, [loading, error]);

  function loadMoreConversations() {
    if (hasNextPage) {
      const nextPage = page + 1;
      dispatch(
        getUserConversations({
          page: nextPage,
          type: type,
        })
      );
    }
  }

  const handleClick = (conversation: IConversation) => {
    dispatch(selectConversation(conversation));
    if (authService.getRole() === CREATOR) {
      navigate(`/creator/conversation/${conversation._id}`);
    } else {
      navigate(`/fan/requestMedia/${conversation._id}`, {
        state: { activeKey },
      });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading === REQUEST_LOADING_STATES.PENDING,
    hasNextPage,
    onLoadMore: loadMoreConversations,
  });

  const conversationsToDisplay = type === CONVERSATION_TYPES.SEARCH ? searchConversations : conversationsData?.data || [];

  // const filteredConversations = unreadFilter ? conversationsToDisplay.filter((conversation) => conversation.hasNewMessages) : conversationsToDisplay;

  // const toggleUnreadFilter = () => {
  //   setUnreadFilter((prev) => !prev);
  // };

  return (
    <Space className="w-full gap-14" direction="vertical">
      {/* {authService.getRole() === CREATOR && (
        <div className={`unread-container ${unreadFilter ? "unread-active" : ""}`} onClick={toggleUnreadFilter}>
          <Typography.Text className="unread-text">{t("unread")}</Typography.Text>
          <img src={unreadFilter ? UnreadActiveIcon : UnreadIcon} className="unread-icon" alt="unread" />
        </div>
      )} */}
      {loading === REQUEST_LOADING_STATES.SUCCEEDED && conversationsToDisplay.length === 0 ? (
        <Typography.Text className="not-conversation-text">{t("you-dont-have-conversations")}</Typography.Text>
      ) : (
        conversationsToDisplay.map((conversation) => {
          return (
            <Row key={conversation._id} className="conversation-row" onClick={() => handleClick(conversation)}>
              <div className={`conversation-details ${type === CONVERSATION_TYPES.TO_DELIVER ? "to-deliver" : ""}`}>
                <div className="avatar-container">
                  <ConversationAvatarComponent
                    userId={conversation.interlocutor?.userId}
                    avatar={conversation.interlocutor?.avatar}
                    name={conversation.interlocutor?.name}
                  />
                </div>
                <div className="conversation-info-container">
                  <div className="conversation-info-user">
                    <Typography.Text className="messageNameLabel">
                      {conversation.interlocutor?.name === "user-not-found" ? t("user-not-found") : conversation.interlocutor?.name}
                    </Typography.Text>
                    <VerifiedUserIconComponent
                      userId={conversation.interlocutor?.userId}
                      verified={conversation.interlocutor?.isVerified}
                      fetch={false}
                    />
                  </div>
                  <div className="conversation-info-status">
                    <Typography.Text className={`messageStatusLabel ${conversation.hasNewMessages ? "text-white-color" : ""}`}>
                      {conversation.lastMessage ? (
                        <span className="w-full">{conversation.lastMessage}</span>
                      ) : (
                        <ConversationOnlineStatusComponent userId={conversation.interlocutor?.userId} className={"messageStatusLabel"} />
                      )}
                    </Typography.Text>
                  </div>
                </div>
              </div>
              <div className="conversation-clock-badge">
                {type === CONVERSATION_TYPES.TO_DELIVER ? (
                  <div className="clock-background">
                    <CountDownComponent targetDate={conversation?.undeliveredMessage?.updatedAt!} showSeconds={false} />
                  </div>
                ) : null}
                {conversation.hasNewMessages && <Badge className="messageNewBadge" status="success" />}
              </div>
            </Row>
          );
        })
      )}
      {(loading === REQUEST_LOADING_STATES.PENDING || hasNextPage) && <SpinnerComponent refProp={sentryRef} />}
    </Space>
  );
};

export default MessageTabListComponent;
