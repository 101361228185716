import "./index.less";

type StickyButtonProps = {
  title: string;
  disabled: boolean;
  handleClickButton: () => void;
};

const StickyButtonComponent: React.FC<StickyButtonProps> = ({ title, disabled, handleClickButton }) => {
  return (
    <div className="sticky-bottom-button-container">
      <button className="sticky-bottom-button" onClick={handleClickButton} disabled={disabled}>
        {title}
      </button>
    </div>
  );
};

export default StickyButtonComponent;
