import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CountDownComponent from "../CountDownComponent";
import "./index.less";

type DeletePromoCodeModalProps = {
  isOpen: boolean;
  title: string;
  discount: string;
  discountType: string;
  promoOn: string;
  numberOfUses: number;
  expireTime: string;
  expireTimeType: string;
  expiresOn: string;
  deleteCodePromo?: () => void;
  onClose?: () => void;
};

const DeletePromoCodeModalComponent: React.FC<DeletePromoCodeModalProps> = ({
  isOpen,
  title,
  discount,
  discountType,
  promoOn,
  numberOfUses,
  expireTime,
  expireTimeType,
  expiresOn,
  deleteCodePromo,
  onClose,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title promo-code">{t(`${title}`)}</Typography.Text>
        </div>
        <div className="bottom-modal-content mt-10">
          <div className="promo-code-container">
            <Typography.Text className="promo-code-modal-row discount">
              {t(`${discount}
                      ${discountType === "PERCENTAGE" ? "% on a" : discountType === "CURRENCY" ? "€ on a" : ""}
                      ${promoOn === "SUBSCRIBERS" ? " subscription" : promoOn === "MEDIA_PUSH" ? " Media Push" : ""}`)}
            </Typography.Text>

            <Typography.Text className="promo-code-modal-row number-of-uses">{t("number-of-uses") + numberOfUses}</Typography.Text>

            <Typography.Text className="promo-code-modal-row number-of-uses">
              {t("duration-of-validity")} : {expireTimeType !== "UNLIMITED" && expireTime} {t(expireTimeType.toLowerCase())}
            </Typography.Text>

            <Typography.Text className="promo-code-modal-row number-of-uses">
              {expiresOn !== null && (
                <>
                  {t("expire-in")} : <CountDownComponent expireAt={expiresOn} showTooltip={false} showDays={true} />
                </>
              )}
            </Typography.Text>
          </div>

          <button className="bottom-modal-button mt-14" onClick={deleteCodePromo}>
            <Typography.Text className="bottom-modal-button-title">{t("Yes-delete")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePromoCodeModalComponent;
