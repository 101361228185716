import React from "react";
import { Space, Row, Tag, Typography } from "antd";
import { IPayoutRequest } from "../../helpers/types";
import dayjs from "dayjs";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";

type props = {
  list?: IPayoutRequest[];
  sentryRef: any;
  loading: boolean;
  hasMore: boolean;
};

const CreatorPaymentHistoryPaymentTabComponent: React.FC<props> = ({ list, sentryRef, loading, hasMore }) => {
  return (
    <div className="payment-container">
      {list &&
        list.map((item, index) => {
          return (
            <Row className="payment-row" key={index}>
              <Space>
                <Tag className="tag">{item.refId}</Tag>
                <Typography.Text className="font-12-regular text-grey-color">{dayjs(item.createdAt).format("DD MMMM YYYY")}</Typography.Text>
              </Space>
              <Typography.Text className="font-13-bold text-white-color">
                {item.netprice?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €
              </Typography.Text>
            </Row>
          );
        })}
      {(loading || hasMore) && <SpinnerComponent refProp={sentryRef} />}
    </div>
  );
};

export default CreatorPaymentHistoryPaymentTabComponent;
