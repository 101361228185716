import { Layout, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import "./index.less";
import AutomaticMessageComponent from "../../../components/AutomaticMessageComponent/AutomaticMessageComponent";

const CreatorAutomaticMessage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Content className="content CreatorAutomaticMessageWrapper gradient-background-blue-settings">
        <Row className="w-full relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={8} navigateTo="/common/setting" />
          <Typography.Text className="header-title-black-30">{t("automation")}</Typography.Text>
        </Row>

        <div>
          <AutomaticMessageComponent />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorAutomaticMessage;
