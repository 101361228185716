import React from "react";
import { Layout, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";
import { USER_TYPES } from "../../helpers/constant";

type props = {
  errorMessage?: string | null;
  type: string;
};

const LiveJoinWaitComponent: React.FC<props> = ({ errorMessage, type }) => {
  const { t } = useTranslation();

  return (
    <Layout.Content className="liveJoinWaitWrapper">
      <Row className="liveJoinWaitContent">
        <div className="liveJoinWaitBlurBg" />
        <Row className="liveJoinWaitLabelWrapper">
          {!errorMessage && <Typography.Text className="font-24-bold text-white-color">{t("loading-live")}</Typography.Text>}
          {errorMessage && <Typography.Text className="font-24-bold text-white-color">{errorMessage}</Typography.Text>}
        </Row>
      </Row>
    </Layout.Content>
  );
};

export default LiveJoinWaitComponent;
