import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, message, Space, Switch, Typography } from "antd";
import PlusWhiteIcon from "../../assets/icons/whitePlusIcon.svg";
import "./index.less";
import { t } from "i18next";
import { automationService } from "../../services/automation.service";
import { IAutomationField } from "../../helpers/types";

const AutomaticMessageComponent = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<IAutomationField[]>([]);
  const shouldFetch = useRef(true);

  const handleModifyClick = (id: any) => {
    navigate(`/creator/message-automatic/${id}`);
  };

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      automationService
        .getCreatorAutomationSettings()
        .then((data: any) => {
          const relevantKeys = ["newSubscriber", "renewSubscription", "unsubscribe", "resubscribe"];
          const formattedMessages = relevantKeys.map((key) => ({
            id: key,
            title: key,
            description: data[key]?.text || "",
            image: data[key]?.fileIds?.[0]?.url ? data[key]?.fileIds?.[0]?.thumbnails?.find((thumbnail : any) => thumbnail?.type === "CLEAR")?.absolutePath : null,
            status: data[key]?.status === "active",
          }));

          setData(formattedMessages);
        })
        .catch((error) => {
          message.error(error || "Something went wrong.");
        });
    }
  }, []);

  const handleSwitchChange = async (data: any) => {
    const fieldName = data.id;
    if (!data.description) {
      handleModifyClick(fieldName);
    } else {
      setData((prevData: any) => prevData.map((message: any) => (message.id === fieldName ? { ...message, status: !message.status } : message)));
      await automationService
        .updateStatus({ fieldName: fieldName })
        .then((res) => {
          if (!res.data) {
            setData((prevData: any) =>
              prevData.map((message: any) => (message.id === fieldName ? { ...message, status: !message.status } : message))
            );
          }
        })
        .catch((err) => {
          message.error(t("something-want-wrong"));
          setData((prevData: any) => prevData.map((message: any) => (message.id === fieldName ? { ...message, status: !message.status } : message)));
        });
    }
  };

  return (
    <div className="AutomaticMessageComponentWrapper">
      <Space direction="vertical" className="gap-15 spaceRow w-100">
        {data?.map((message: IAutomationField) => (
          <Col key={message?.id} className="contentBox">
            <div className={`rectangularShape ${!message?.image || message?.image === null ? "add-media" : ""}`}>
              {message?.image ? (
                <img src={message?.image} width="100%" height="100%" alt="Creator Image" className="fit-cover" />
              ) : (
                <div className={`addCircle `}>
                  <img src={PlusWhiteIcon} width={70} alt="plus" />
                </div>
              )}
            </div>
            <div className="contentWrapper">
              <h2 className="font-18-bold">{t(message?.title)}</h2>
              <Typography.Paragraph
                className="message"
                ellipsis={{
                  rows: 3,
                  expandable: false,
                  symbol: "...",
                }}
              >
                {t(`${message?.id}-default-description`)}
              </Typography.Paragraph>
              <div className="buttonGroup">
                <button className="modifyButton" onClick={() => handleModifyClick(message?.id)}>
                  {t("To-modify")}
                </button>
                <Switch className="switch revenueSwitch" checked={message?.status} onChange={() => handleSwitchChange(message)} />
              </div>
            </div>
          </Col>
        ))}
      </Space>
    </div>
  );
};

export default AutomaticMessageComponent;
