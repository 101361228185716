import type { TabsProps } from "antd";
import { Layout, Row, Space, Tabs, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/icons/edit.svg";
import SearchIcon from "../../../assets/icons/search-white.svg";
import Flame from "../../../assets/images/flame.webp";
import Popcorn from "../../../assets/images/popcorn.webp";
import ToDeliver from "../../../assets/images/to-deliver.webp";
import FooterComponent from "../../../components/FooterComponent";
import MessageTabLabelComponent from "../../../components/MessageTabLabelComponent";
import MessageTabListComponent from "../../../components/MessageTabListComponent";
import SearchComponent from "../../../components/SearchComponent";
import { CONVERSATION_TYPES, C_CURRENT_TAB, FOOTER_IDS } from "../../../helpers/constant";
import { searchConversation } from "../../../redux/messages/messagesSlice";
import { AppDispatch } from "../../../redux/store";
import { messageService, userService } from "../../../services";
import { socket } from "../../../socket";
import "./index.less";
import FormationModalComponent from "../../../components/Formations/Creators/Modals";
import { checkModalStatus, updateModalStatus } from "../../../utls/formationModalUtils";

const CreatorMessagePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const [types, setTypes] = useState({
    [CONVERSATION_TYPES.TO_DELIVER]: false,
    [CONVERSATION_TYPES.GENERAL]: false,
    [CONVERSATION_TYPES.SUBSCRIPTION]: false,
  });
  const [activeKey, setActiveKey] = useState(window.localStorage.getItem(C_CURRENT_TAB) || CONVERSATION_TYPES.SUBSCRIPTION);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [formationModalIsVisible, setFormationModalIsVisible] = useState<boolean>(false);

  useEffect(() => {
    checkModalStatus("hasSeenChatModal", setFormationModalIsVisible);

    if (shouldFetch.current) {
      shouldFetch.current = false;
      messageService
        .getUnreadByTypes([CONVERSATION_TYPES.GENERAL, CONVERSATION_TYPES.SUBSCRIPTION, CONVERSATION_TYPES.TO_DELIVER])
        .then((data) => {
          updateTypes(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    socket.on("unread-messages", handleUnreadMessages);
    return () => {
      socket.off("unread-messages", handleUnreadMessages);
    };
  }, []);

  function handleUnreadMessages(data: any) {
    updateTypes(data);
  }

  const updateTypes = (data: any) => {
    if (!isEmpty(data)) {
      let types: any = {};
      data.forEach((object: { type: string; hasNewMessages: any }) => {
        types[object.type] = object.hasNewMessages;
      });
      setTypes(types);
    }
  };

  const onChangeTab = (activeKey: string) => {
    setActiveKey(activeKey);
    window.localStorage.setItem(C_CURRENT_TAB, activeKey);
  };

  const toggleSearchVisibility = () => {
    setIsSearchVisible((prev) => !prev);
  };

  const tabs: TabsProps["items"] = [
    {
      key: CONVERSATION_TYPES.TO_DELIVER,
      label: (
        <MessageTabLabelComponent
          icon={ToDeliver}
          label={t("to-deliver")}
          isNew={types[CONVERSATION_TYPES.TO_DELIVER]}
          isActive={activeKey === CONVERSATION_TYPES.TO_DELIVER}
        />
      ),
      children: <MessageTabListComponent user="creator" type={CONVERSATION_TYPES.TO_DELIVER} />,
    },
    {
      key: CONVERSATION_TYPES.GENERAL,
      label: (
        <MessageTabLabelComponent
          icon={Popcorn}
          label={t("general")}
          isNew={types[CONVERSATION_TYPES.GENERAL]}
          isActive={activeKey === CONVERSATION_TYPES.GENERAL}
        />
      ),
      children: <MessageTabListComponent user="creator" type={CONVERSATION_TYPES.GENERAL} />,
    },
    {
      key: CONVERSATION_TYPES.SUBSCRIPTION,
      label: (
        <MessageTabLabelComponent
          icon={Flame}
          label={t("your-subscriptions")}
          isNew={types[CONVERSATION_TYPES.SUBSCRIPTION]}
          isActive={activeKey === CONVERSATION_TYPES.SUBSCRIPTION}
        />
      ),
      children: <MessageTabListComponent user="creator" type={CONVERSATION_TYPES.SUBSCRIPTION} />,
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value.length >= 3) {
      dispatch(searchConversation(value));
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  };

  const getIt = async () => {
    updateModalStatus("hasSeenChatModal", setFormationModalIsVisible);
  };

  return (
    <Layout>
      <Layout.Content className="content creatorMessageContentWrapper gradient-background-black-blue">
        <Row className="justify-content-between header-title-container-95 mb-6">
          <Typography.Text className="header-title-black-35">{t("messaging")}</Typography.Text>
          <Space className="gap-13">
            <img src={EditIcon} width={26} alt="edit" className="cursor-pointer" onClick={() => navigate("/common/messages/new")} />
            <img src={SearchIcon} width={24} alt="edit" className="cursor-pointer" onClick={() => toggleSearchVisibility()} />
          </Space>
        </Row>
        {isSearchVisible && (
          <Row className="mb-20">
            <SearchComponent placeholder={t("search-2")} handleChange={handleSearch} />
          </Row>
        )}
        {isSearching ? (
          <MessageTabListComponent user="creator" type={CONVERSATION_TYPES.SEARCH} />
        ) : (
          <Tabs
            className="chatTabs"
            // className="chatTabs creator-nav-tabs"
            centered
            activeKey={activeKey}
            items={tabs}
            destroyInactiveTabPane={true}
            onChange={onChangeTab}
          />
        )}
        {formationModalIsVisible && (
          <FormationModalComponent
            id="chat"
            visible={formationModalIsVisible}
            onClose={() => {
              setFormationModalIsVisible(false);
            }}
            getIt={getIt}
          />
        )}
      </Layout.Content>
      <FooterComponent type="creator" page={FOOTER_IDS.MESSAGING} />
    </Layout>
  );
};

export default CreatorMessagePage;
