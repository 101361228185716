import { Space, Typography, Upload, message } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoIcon from "../../assets/icons/jouer.svg";
import LockIcon from "../../assets/icons/lock-outlined.svg";
import PlusIcon from "../../assets/icons/plus-black.svg";
import { fileService } from "../../services";
import { compressImage, convertHeicFile, generateThumbnailURL, isImageAcceptedFormat } from "../../utls/FunctionsUtil";
import MailboxMediaModal from "../MailboxMediaModal";
import "./index.less";

type props = {
  disabled?: boolean;
  maxCount: number;
  defaultList?: any[];
  onFilesChange?: (fileList: UploadFile<[]>[]) => void;
  allFiles?: any;
  fromCollection?: boolean;
  currentModule?: string;
};

const AutomationMediaComponent: React.FC<props> = ({
  disabled = false,
  maxCount,
  defaultList,
  onFilesChange,
  allFiles,
  fromCollection = false,
  currentModule,
}) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>(allFiles);
  const [isMediaModal, setIsMediaModal] = useState(false);
  const uploadRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const paramsFiles: Array<any[]> = JSON.parse(params.get("selected"));
  const [paramIds, setParamIds] = useState<any>([]);
  const { block } = useParams();

  const getAllSelectedFiles = async (fileIds: Array<any>) => {
    const selectedFileIds = fileList.map((file) => file.uid);
    const filesToFetch = fileIds.filter((id) => !selectedFileIds.includes(id));
    await fileService.allSelectedFiles(filesToFetch).then((response: any) => {
      const filesToAdd: any = [];
      response.forEach((item: any) => {
        let fileType = "";
        let fileExt = "";
        let isVideo = false;
        if (item.url.includes(".mp4")) {
          fileType = "video/mp4";
          fileExt = "mp4";
          isVideo = true;
        } else {
          fileType = "image/webp";
          fileExt = "webp";
        }
        let originFileObj2 = {
          isPrivate: true,
          status: "done",
          uid: item._id,
          name: item._id,
          percent: 0,
          thumbUrl: "",
          type: "quick-link",
        };
        let JsonString = JSON.stringify(originFileObj2);
        let blob = new Blob([JsonString], { type: fileType });
        let file = new File([blob], `newQuickFile.${fileExt}`, { type: fileType });

        filesToAdd.push({
          ...item,
          imgType: "quick-file",
          isPrivate: true,
          status: "done",
          uid: item._id,
          name: item._id,
          percent: 0,
          thumbUrl: isVideo ? item?.thumbnailUrl : item?.url,
          type: fileType,
          originFileObj: file,
        });
      });

      setFileList((prevFiles: any) => {
        return [...filesToAdd, ...prevFiles];
      });
      navigate({
        pathname: location.pathname, 
        search: "",
      });
    });
  };

  useEffect(() => {
    if (paramsFiles?.length !== 0) {
      setParamIds(paramsFiles);
    }
    return () => {
      setParamIds([]);
    };
  }, []);

  useEffect(() => {
    if (paramIds !== null && paramIds !== undefined && paramIds?.length !== 0) {
      getAllSelectedFiles(paramIds);
    }
  }, [paramIds]);

  useEffect(() => {
    if (onFilesChange !== undefined) {
      onFilesChange(fileList);
    }
  }, [fileList]);

  useEffect(() => {
    if (fileList.length === 0 || allFiles.length !== fileList.length) {
      setFileList(allFiles);
    }
  }, [allFiles.length]);

  const customItemRender = (originNode: React.ReactNode, file: any) => {
    let lock: boolean = false;
    let isThumbnail: boolean = false;
    let isVideo: boolean = file.type?.includes("video")!;
    allFiles?.forEach((e : any) => {
      if (file.uid === e.uid) {
        if (e.isPrivate) {
          lock = true;
        }
        isThumbnail = e.isThumbnail;
      }
    });

    return (
      <>
        {originNode}
        {isThumbnail && (
          <div className="collection-thumbnail">
            <Typography.Text className="collection-thumbnail-text">{t("miniature")}</Typography.Text>
          </div>
        )}
        {lock && (
          <img
            src={LockIcon}
            width={30}
            style={{ position: "relative", top: isThumbnail ? -110 : -90, right: -35, color: "white" }}
            alt="lock icon"
          />
        )}
        {isVideo && (
          <img src={VideoIcon} style={!lock ? { top: -147, right: -73 } : { top: -160, right: -45 }} alt="video icon" className="videoIcon" />
        )}
      </>
    );
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        return false;
      } else if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false);
        // convert file
        const convertedFile = await convertHeicFile(file);
        const compressedImage = await compressImage(convertedFile);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        return false;
      } else if (file.type.includes("video/")) {
        handleConvertedFile(file.uid, file, true);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = async (uid: string, file: File, converted: boolean) => {
    if (!converted) {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "uploading",
        percent: 50,
      };
      setFileList((prevList) => [fileObj, ...prevList]);
    } else {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "done",
        type: file.type,
        size: file.size,
        originFileObj: file as RcFile,
      };
      if (file.type.includes("video")) {
        const result = await generateThumbnailURL(file as RcFile);
        fileObj.url = result;
      }
      setFileList((prevList) => [fileObj, ...prevList.filter((file) => file.uid !== fileObj.uid)]);
    }
  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const removeUnsupportedFiles = (uid: string) => {
    setFileList((prevList) => [...prevList.filter((file) => file.uid !== uid)]);
  };

  const onRemove = async (file: UploadFile<any>) => {
    setFileList((prev: any) =>
      prev.filter((f: any) => {
        return f.uid !== file.uid;
      })
    );
  };

  const handleDeviceClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
      setIsMediaModal(false);
    }
  };

  return (
    <Space
      className={`w-full ${
        currentModule && currentModule === "automation" && fileList.length === 0
          ? "addMediaWrapperRectangularShape justify-content-center"
          : fileList.length === 0
            ? "mediaPushMediaWrapper"
            : "addMediaWrapperRectangularShape"
      } `}
    >
      {currentModule &&
        currentModule === "automation" &&
        (fileList.length === 0 || fileList.length > maxCount ? (
          <div className="rectangularShape" onClick={() => setIsMediaModal(true)}>
            <div className={`addCircle ${disabled ? "disabled" : ""}`}>
              <img src={PlusIcon} width={70} alt="plus" />
            </div>
          </div>
        ) : (
          fileList.length < maxCount && (
            <div className="rectangularShape" onClick={() => setIsMediaModal(true)}>
              <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                <img src={PlusIcon} width={70} alt="plus" />
              </div>
            </div>
          )
        ))}

      <MailboxMediaModal
        isOpen={isMediaModal}
        onClose={() => setIsMediaModal(false)}
        onMyAlbums={() => {
            navigate(`/creator/quickLink?id=automation&block=${block}`)
        }}
        onMyDevice={handleDeviceClick}
      />

      <div>
        <Upload
          accept="image/*,video/*"
          disabled={disabled}
          action=""
          itemRender={(originNode: any, file: any) => customItemRender(originNode, file)}
          defaultFileList={defaultList}
          fileList={fileList}
          listType="picture-card"
          maxCount={maxCount}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          isImageUrl={isImageUrl}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
          }}
          multiple={true}
        >
          <div ref={uploadRef}></div>
        </Upload>
      </div>
    </Space>
  );
};

export default AutomationMediaComponent;
