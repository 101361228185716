import { Col, Input, Layout, message, Row, Space, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import { getMonetizationsInformations, MAX_PRICE } from "../../../helpers/constant";
import { IMonetization } from "../../../helpers/types";
import { AppDispatch } from "../../../redux/store";
import { getCreatorMonetizations, updateMonetizations } from "../../../redux/users/monetizationsSlice";
import { authService } from "../../../services";
import "./index.less";
import { SUBSCRIPTION_PRICING_LIMITS } from "../../../utls/constants";

const CreatorMonetizationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [monetizations, setMonetizations] = useState<any>({});
  const monetizationsInformations = getMonetizationsInformations(t);
  const [disabledBtn, setdisbledBtn] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const id = authService.getId();
    const resp = dispatch(getCreatorMonetizations(id)) as any;
    resp
      .then((data: any) => {
        let monetization = data.payload;
        monetization = {
          ...monetization,
          monthly: true,
          trimestrial: monetization.trimestrialPrice ? true : false,
          // half_yearly: monetization.half_yearlyPrice ? true : false,
          // yearly: monetization.yearlyPrice ? true : false,
        };
        setMonetizations(monetization);
      })
      .catch((error: any) => {
        message.error(error || "Something went wrong.");
      });
  }, []);

  const updateMonetization = async () => {
    const monetizationsToUpdate: IMonetization = {};
    if (monetizations.monthly) {
      if (!monetizations.monthlyPrice || monetizations.monthlyPrice === "0") {
        message.error(t("monetization-monthly-error"));
        return;
      } else if (monetizations.monthlyPrice > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
        return;
      }
      monetizationsToUpdate.monthlyPrice = parseFloat(monetizations.monthlyPrice);
    }
    if (monetizations.trimestrial) {
      if (!monetizations.trimestrialPrice || monetizations.trimestrialPrice === "0") {
        message.error(t("monetization-trimestrial-error"));
        return;
      } else if (monetizations.trimestrialPrice > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
        return;
      }
      monetizationsToUpdate.trimestrialPrice = parseFloat(monetizations.trimestrialPrice);
    }
    // if (monetizations.half_yearly) {
    //   if (!monetizations.half_yearlyPrice || monetizations.half_yearlyPrice === "0") {
    //     message.error(t("monetization-half-yearly-error"));
    //     return;
    //   } else if (monetizations.half_yearlyPrice > MAX_PRICE) {
    //      message.warning(t("maximum-price-alert"));
    //      return;
    //   }
    //   monetizationsToUpdate.half_yearlyPrice = parseFloat(monetizations.half_yearlyPrice);
    // }
    // if (monetizations.yearly) {
    //   if (!monetizations.yearlyPrice || monetizations.yearlyPrice === "0") {
    //     message.error(t("monetization-yearly-error"));
    //     return;
    //   } else if (monetizations.yearlyPrice > MAX_PRICE) {
    //    message.warning(t("maximum-price-alert"));
    //    return;
    //   }
    //   monetizationsToUpdate.yearlyPrice = parseFloat(monetizations.yearlyPrice);
    // }
    const resp = await dispatch(updateMonetizations(monetizationsToUpdate));
    if (resp.meta.requestStatus === "fulfilled") {
      const payload = resp.payload as any;
      if (payload.data?.message) {
        message.success(t(payload.data.message));
        navigate("/common/setting");
      }
      if (payload.message) {
        message.error(t(payload.message));
      }
    }
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>, frequency: string) => {
    if (!monetizations[frequency]) {
      return;
    }
    const inputValue = e.target.value;
    const regexPattern = /^[0-9]+$/;
    if (regexPattern.test(inputValue) || inputValue === "") {
      let value = Number(inputValue);
      if (frequency === "monthly" && value > SUBSCRIPTION_PRICING_LIMITS.MONTHLY) {
        message.warning(t("monthly-price-cannot-exceed-300-euros"));
        return;
      } else if (frequency === "trimestrial" && value > SUBSCRIPTION_PRICING_LIMITS.TRIMESTRIAL) {
        message.warning(t("quarterly-price-cannot-exceed-500-euros"));
        return;
      }

      setMonetizations((prevMonetizations: any) => {
        const updatedMonetizations = { ...prevMonetizations };
        switch (frequency) {
          case "monthly":
            updatedMonetizations.monthlyPrice = inputValue;
            break;
          case "trimestrial":
            updatedMonetizations.trimestrialPrice = inputValue;
            break;
        }
        // Update hasChanges if there are differences between the updated and original monetizations
        setHasChanges(JSON.stringify(updatedMonetizations) !== JSON.stringify(monetizations));
        return updatedMonetizations;
      });
    }

    let value = Number(inputValue);
    if (value >= MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
    }
  };

  const handleSwitchChange = async (e: any, frequency: string) => {
    switch (frequency) {
      case "trimestrial":
        setMonetizations({ ...monetizations, trimestrial: e, trimestrialPrice: 0 });
        break;
      // case "half_yearly":
      //   setMonetizations({ ...monetizations, half_yearly: e , half_yearlyPrice : 0 });
      //   break;
      // case "yearly":
      //   setMonetizations({ ...monetizations, yearly: e , yearlyPrice : 0 });
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      monetizations.monthlyPrice === 5 &&
      monetizations.trimestrialPrice === 15
      // monetizations.half_yearlyPrice === 30 &&
      // monetizations.yearlyPrice === 60
    ) {
      setdisbledBtn(true);
    } else {
      setdisbledBtn(false);
    }
  }, [monetizations]);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const validateMinimumPrice = (frequency: string) => {
    const price = frequency === "monthly" ? monetizations.monthlyPrice : frequency === "trimestrial" ? monetizations.trimestrialPrice : null;

    if (price !== null && price !== undefined && price < SUBSCRIPTION_PRICING_LIMITS.MINIMUM) {
      message.error(t("minimum-price-is-5-euros"), 4);
    }
  };

  const isContinueButtonDisabled = () => {
    return (
      (monetizations.monthly && (monetizations.monthlyPrice || 0) < SUBSCRIPTION_PRICING_LIMITS.MINIMUM) ||
      (monetizations.trimestrial && (monetizations.trimestrialPrice || 0) < SUBSCRIPTION_PRICING_LIMITS.MINIMUM)
    );
  };

  return (
    <Layout>
      <Layout.Content className="content fanMonetizationsContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={1} />
          <Typography.Text className="header-title-black-24">{t("subscription-price")}</Typography.Text>
        </Row>
        <Row className="pl-20 pr-13 mt-50">
          <Space className="w-full gap-39" direction="vertical">
            {monetizationsInformations.map((monetization, index) => {
              return (
                <Col key={index}>
                  <Row>
                    <Space>
                      <Typography.Text className="authRevenueItemTitle">{monetization.title}</Typography.Text>
                      {monetization.important && <Typography.Text className="authRevenueItemImportantTitle">*</Typography.Text>}
                    </Space>
                  </Row>
                  <Row className="mt-10 justify-content-between items-center">
                    <Space className="d-flex items-end">
                      <Input
                        className="authInput authRevenueInput"
                        type="text"
                        value={
                          monetization.frequency === "monthly"
                            ? monetizations.monthlyPrice
                              ? monetizations.monthlyPrice
                              : null
                            : monetization.frequency === "trimestrial"
                              ? monetizations.trimestrialPrice
                                ? monetizations.trimestrialPrice
                                : null
                              : null
                          // : monetization.frequency === "half_yearly"
                          //   ? monetizations.half_yearlyPrice
                          //     ? monetizations.half_yearlyPrice
                          //     : null
                          //   : monetization.frequency === "yearly"
                          //     ? monetizations.yearlyPrice
                          //       ? monetizations.yearlyPrice
                          //       : null
                          //     : null
                        }
                        placeholder="0.00€"
                        onChange={(e) => {
                          handleInputChange(e, monetization.frequency);
                        }}
                        onBlur={() => validateMinimumPrice(monetization.frequency)}
                        onFocus={handleFocus}
                        inputMode="numeric"
                      />
                      <Typography.Text className="authRevenueItemSubTitle">{monetization.subTitle}</Typography.Text>
                    </Space>
                    <Switch
                      className="switch revenueSwitch"
                      checked={
                        monetization.frequency === "monthly"
                          ? true
                          : monetization.frequency === "trimestrial"
                            ? monetizations.trimestrial
                              ? true
                              : false
                            : // : monetization.frequency === "half_yearly"
                              //   ? monetizations.half_yearly
                              //     ? true
                              //     : false
                              //   : monetization.frequency === "yearly"
                              //     ? monetizations.yearly
                              //       ? true
                              //       : false
                              false
                      }
                      onChange={(e) => {
                        handleSwitchChange(e, monetization.frequency);
                      }}
                    />
                  </Row>
                </Col>
              );
            })}
          </Space>
        </Row>
        <Row className="mt-50 pl-21 pr-20">
          <AuthButtonComponent
            label={t("continue")}
            onClick={updateMonetization}
            disabled={!hasChanges || disabledBtn || isContinueButtonDisabled()}
          />
        </Row>
        <Row className="mt-24">
          <Typography.Text className="font-10-regular text-grey-color text-center">{t("monetization-terms")}</Typography.Text>
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorMonetizationPage;
