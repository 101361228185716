import { Layout, Typography } from "antd";
import ChevronBack from "../../../../assets/icons/chevron-back-other-profile.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Suspense, useLayoutEffect } from "react";
import { FORMATION_CONFIG } from "../../../../helpers/formationConfig";
import SpinnerComponent from "../../../../components/SpinnerComponent/SpinnerComponent";
import "./index.less";

const FormationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const config = FORMATION_CONFIG[id || ""];
  const { title, component: Component } = config;

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <Layout className="no-scroll-horizontal">
      <Layout.Content className="creator-formation-page-wrapper">
        <div className="formation-page-header">
          <img
            className="formation-chevron-back"
            src={ChevronBack}
            alt="back"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Typography.Text
            className={`formation-title-page ${id === "training-video" || id === "generate-traffic" || id === "presentation-videos" ? "small-title" : ""}`}
          >
            {t(title)}
          </Typography.Text>
        </div>
        <Suspense
          fallback={
            <div className="w-full d-flex justify-content-center mt-20">
              <SpinnerComponent />
            </div>
          }
        >
          <Component />
        </Suspense>
      </Layout.Content>
    </Layout>
  );
};

export default FormationPage;
