import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal, Row, Col, Button, Radio, Typography, RadioChangeEvent, message } from "antd";
import { useTranslation } from "react-i18next";
import InputComponent from "../InputComponent";
import AuthButtonComponent from "../AuthButtonCompnent";
import { getMediaAmounts } from "../../helpers/constant";
import { ModalMediaType } from "../../helpers/types";
import EuroIcon from "../../assets/icons/euro.svg";
import "./index.less";
import MessageTextAreaComponent from "../MessageTextAreaComponent";
import TextArea from "antd/es/input/TextArea";

type props = {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (message: any) => void;
};

const CreatorMessageAcceptMediaModalComponent: React.FC<props> = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const mediaAmounts = getMediaAmounts(t);
  const [media, setMedia] = useState<ModalMediaType>({
    amount: mediaAmounts[0].value,
    customAmount: "",
    message: "",
  });

  const handleRadioChange = (e: RadioChangeEvent) => {
    setMedia((prevState) => {
      return {
        ...prevState,
        amount: e.target.value,
        customAmount: "",
      };
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMedia((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        amount: e.target.name === "customAmount" && e.target.value !== "" ? "" : prevState.amount,
      };
    });
  };

  const handleSubmit = () => {
    if (media.message === "") {
      message.error(t("missing-message-error-message"));
      return;
    }
    if (onSubmit) onSubmit(media);
    setMedia({
      amount: mediaAmounts[0].value,
      customAmount: "",
      message: "",
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="creatorMessageAcceptMediaModal"
      open={isOpen}
      centered
      closable={false}
      footer={
        <Col className="creatorMessageAcceptMediaFooterCancelRow">
          <Row className="mt-25">
            <AuthButtonComponent label={t("send")} onClick={handleSubmit} />
          </Row>
          <Button className="rejectBtn" type="link" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Col>
      }
    >
      <Row>
        <Typography.Text className="font-24-bold text-white-color">{t("amount")}</Typography.Text>
      </Row>
      <Row className="mt-14">
        <Radio.Group className="w-full" defaultValue={mediaAmounts[0].value} value={media.amount.toString()}>
          <Row gutter={5}>
            {mediaAmounts.map((amount, index) => {
              return (
                <Col className="mt-3" key={index} span={6}>
                  <Radio.Button className="mediaAmountRadioButton acceptModal" value={amount.value} onChange={handleRadioChange}>
                    {amount.label}
                  </Radio.Button>
                </Col>
              );
            })}
          </Row>
        </Radio.Group>
      </Row>
      <Row className="mt-25">
        <Typography.Text className="font-24-bold text-white-color">{t("personalized-amount")}</Typography.Text>
      </Row>
      <Row className="customAmountWrapper mt-11">
        <InputComponent name={"customAmount"} className="accept-modal-input" suffix="€" value={media.customAmount} onChange={handleInputChange} />
      </Row>
      <Row className="mt-30">
        <Typography.Text className="font-24-bold text-white-color">{t("your-message")}</Typography.Text>
      </Row>
      <Row className="mt-11">
        <TextArea
          name="message"
          className="text-area-accept-modal"
          placeholder={`${t("your-message")}...`}
          value={media.message}
          onChange={handleInputChange}
          rows={2}
        />
      </Row>
    </Modal>
  );
};

export default CreatorMessageAcceptMediaModalComponent;
