import React, { useState } from "react";
import "./index.less";

interface FanProfileTrainingOverlayComponentProps {
  step: number;
  fanModalStep: () => void;
}

const FanProfileTrainingOverlayComponent: React.FC<FanProfileTrainingOverlayComponentProps> = ({ step, fanModalStep }) => {
  return (
    <div className="fan-profile-overlay" onClick={fanModalStep}>
      <div className="fan-profile-overlay-footer">
        <p className="fan-profile-overlay-text">
          {step === 1 ? "Cliquez ici pour voir la collection des créateurs" : "Et ici pour voir ses publications"}
        </p>
        <button className="fan-profile-overlay-button" onClick={fanModalStep}>
          {step === 1 ? "Suivant" : "Terminé"}
        </button>
      </div>
    </div>
  );
};

export default FanProfileTrainingOverlayComponent;
