import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ThanksImg from "../../../assets/images/thanks.webp";
import React from "react";
import { APP_NAME } from "../../../utls/constants";
import "../index.less";

type SignUpCreatorThanksProps = {
  userEmail: string;
  handleAuthCompletion: () => void;
};

const SignUpCreatorThanksComponent: React.FC<SignUpCreatorThanksProps> = ({ userEmail, handleAuthCompletion }) => {
  const { t } = useTranslation();

  return (
    <div className="auth-thanks-page-wrapper">
      <Row className="justify-content-center mt-20">
        <img src={ThanksImg} className="thanks-img" alt="completed" />
      </Row>
      <div className="auth-thanks-page-container">
        <Row>
          <Typography.Text className="auth-thanks-page-title">{t("auth-thanks-page-title")}</Typography.Text>
        </Row>
        <Row className="mt-30">
          <Typography.Text className="auth-thanks-page-desc">{t("auth-thanks-page-desc-1")}</Typography.Text>
        </Row>
        <Row className="mt-30">
          <Typography.Text className="auth-thanks-page-desc">{t("auth-thanks-page-desc-2")}</Typography.Text>
        </Row>
        <Row className="mt-30">
          <Typography.Text className="auth-thanks-page-desc">
            {t("auth-thanks-page-desc-3-1")}
            <span className="email-highlight"> {userEmail}</span>
            {t("auth-thanks-page-desc-3-2", { appName: APP_NAME })}
          </Typography.Text>
        </Row>

        <Row className="mt-30 mb-40">
          <Typography.Text className="auth-thanks-page-desc">{t("auth-thanks-page-desc-4", { appName: APP_NAME })}</Typography.Text>
        </Row>
      </div>
    </div>

    // <Col className="padding-container">
    //   <Row className="justify-content-center mt-20">
    //     <img src={CompletedIcon} alt="completed" />
    //   </Row>
    //   <Row className="justify-content-center mt-35">
    //     <Typography.Text className="authCompletedTitle">
    //       <span className="authCompletedTitleSpan">{t("auth-signUp-finish-title-1")}</span>
    //       {t("auth-signUp-finish-title-2")}
    //     </Typography.Text>
    //   </Row>
    //   <Row className="mt-40">
    //     <Typography.Text className="authCompletedContent">{t("auth-signUp-receive-email-notification-label")}</Typography.Text>
    //   </Row>
    //   <Row className="mt-40">
    //     <Typography.Text className="authCompletedContent text-medium-green-color">{userEmail}</Typography.Text>
    //   </Row>
    //   <Row className="mt-40">
    //     <Typography.Text className="authCompletedContent">{t("auth-signUp-with-status")}</Typography.Text>
    //   </Row>
    //   <Row className="justify-content-center mt-40">
    //     <AuthButtonComponent label={t("terminate-label")} onClick={handleAuthCompletion} />
    //   </Row>
    // </Col>
  );
};

export default SignUpCreatorThanksComponent;
