import { useParams, Navigate } from "react-router-dom";

const RedirectToProfileComponent = () => {
  const { username } = useParams();

  if (!username) {
    return <Navigate to="/" replace />;
  }

  return <Navigate to={`/p/${username}`} replace />;
};

export default RedirectToProfileComponent;
