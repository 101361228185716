import { userService } from "../services";

/**
 * Checks the visibility status of a modal by retrieving it from local storage 
 * and optionally from an external API if the status is not already stored locally.
 * 
 * @param modalKey - The unique key identifying the modal (e.g., "hasSeenProfileModal").
 * @param getStatus - A function to fetch the modal status from an external API.
 *                     Should return a promise resolving to an object containing a `value` boolean.
 * @param setModalVisibility - A function to update the visibility state of the modal.
 */
export const checkModalStatus = async (
    modalKey: string,
    setModalVisibility: (visible: boolean) => void
  ) => {
    // Check local storage for the modal's visibility status
    const hasSeenModal = localStorage.getItem(modalKey);
    
    if (hasSeenModal === null || hasSeenModal === "false") {
      try {
        // Fetch the modal status from the external API
        const data = await userService.getModalStatus(modalKey);
  
        if (!data.value) {
          // If the modal has not been seen, make it visible
          setModalVisibility(true);
        } else {
          // Otherwise, mark it as seen locally
          localStorage.setItem(modalKey, "true");
          setModalVisibility(false);
        }
      } catch (error) {
        console.error(`Error checking modal status for ${modalKey}:`, error);
      }
    }
};

/**
 * Updates the visibility status of a modal both locally and via an external API.
 * 
 * @param modalKey - The unique key identifying the modal (e.g., "hasSeenChatModal").
 * @param updateStatus - A function to update the modal status on the external API.
 *                        Should return a promise resolving when the status is updated successfully.
 * @param setModalVisibility - A function to update the visibility state of the modal.
*/
export const updateModalStatus = async (
    modalKey: string,
    setModalVisibility: (visible: boolean) => void
  ) => {
    try {
      // Update the modal status via the external API
      await userService.updateModalStatus(modalKey);
  
      // Mark the modal as seen in local storage
      localStorage.setItem(modalKey, JSON.stringify(true));
  
      // Hide the modal
      setModalVisibility(false);
    } catch (error) {
      console.error(`Error updating modal status for ${modalKey}:`, error);
    }
};
  