import { Col, Layout, Radio, Row, Space, Spin, Switch, Typography, UploadFile, message } from "antd";
import { RcFile } from "antd/es/upload";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowLeftIcon from "../../../assets/icons/new-arrow-left.svg";
import AutomationMediaComponent from "../../../components/AddMediaComponent/AutomationMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import InputComponent from "../../../components/InputComponent";
import MessageTextAreaComponent from "../../../components/MessageTextAreaComponent";
import PriceInputComponent from "../../../components/PriceInputComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import StickyButtonComponent from "../../../components/StickyButtonComponent";
import { MAX_MEDIAS_TO_SEND, MAX_PRICE, getMediaAmounts } from "../../../helpers/constant";
import { FilePrivacy, IMediapush } from "../../../helpers/types";
import { setSelectFiles } from "../../../redux/mediaPush/mediaPushSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { storageService } from "../../../services";
import { automationService } from "../../../services/automation.service";
import { socket } from "../../../socket";
import { generateThumbnailURL, generateURL } from "../../../utls/FunctionsUtil";
import "./index.less";

type MediaPushFiles = UploadFile & { isPrivate: boolean };

type PrivateType = {
  showDialog: boolean;
  fileId: string;
  fileSrc: any;
  fileType: string;
};

const CreatorAutomaticMessageEditPage = () => {
  const { block } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const mediaAmounts = getMediaAmounts(t);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const paramsFiles = searchParams.get("selected");
  const shouldFetch = useRef(true);
  const [radioValue, setRadioValue] = useState<string | undefined>(undefined);
  const [customPrice, setCustomPrice] = useState<number | undefined>(undefined);
  const [promoCodeChecked, setPromoCodeChecked] = useState(false);
  const [promoPercentage, setPromoPercentage] = useState(false);
  const [promoCurrency, setPromoCurrency] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState<string | undefined>(undefined);
  const [discountCurrency, setDiscountCurrency] = useState<string | undefined>(undefined);
  const [automation, setAutomation] = useState<IMediapush>({});
  const [privateFiles, setPrivateFiles] = useState<PrivateType[]>([]);
  const [defaultList, setDefaultList] = useState<PrivateType[]>([]);
  const { files, quickFiles } = useSelector((state: StoreState) => state.mediaPushes);
  const [loading, setLoading] = useState(false);
  const [componentKey, setComponentKey] = useState(0);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  useEffect(() => {
    if (shouldFetch.current) {
      if (!paramsFiles || paramsFiles === null) {
        dispatch(setSelectFiles({ files: [], quickFiles: [] }));
      }
      shouldFetch.current = false;
      automationService
        .getCreatorAutomationFieldData(block)
        .then((data: any) => {
          if (data) {
            setAutomation(data);
            setPromoCodeChecked(data.promo);
            if (data.promo) {
              if (data.discountType === "CURRENCY") {
                setDiscountCurrency(data.discount);
                setPromoCurrency(true);
              } else {
                setDiscountPercentage(data.discount);
                setPromoPercentage(true);
              }
            }
            const filesToAdd: any = [];
            data?.fileIds?.forEach((item: any) => {
              let fileType = "";
              let fileExt = "";
              let isVideo = false;
              if (item.url.includes(".mp4")) {
                fileType = "video/mp4";
                fileExt = "mp4";
                isVideo = true;
              } else {
                fileType = "image/webp";
                fileExt = "webp";
              }
              let originFileObj2 = {
                isPrivate: true,
                status: "done",
                uid: item.id,
                name: item.id,
                percent: 0,
                thumbUrl: "",
                type: "quick-link",
              };
              let JsonString = JSON.stringify(originFileObj2);
              let blob = new Blob([JsonString], { type: fileType });
              let file = new File([blob], `newQuickFile.${fileExt}`, { type: fileType });

              filesToAdd.push({
                imgType: "quick-file",
                isPrivate: item.isPrivate,
                status: "done",
                uid: item.id,
                name: item.id,
                percent: 0,
                thumbUrl: isVideo ? item?.thumbnails?.find((thumbnail: any) => thumbnail?.type === "CLEAR")?.absolutePath : item?.url,
                type: fileType,
                originFileObj: file,
              });
            });
            if (!paramsFiles || paramsFiles === null) {
              dispatch(setSelectFiles({ files: filesToAdd, quickFiles: filesToAdd }));
            }
            if (data.price) {
              handleRadioChange({ target: { value: data.price } });
            }
          }
        })
        .catch((error) => {
          message.error(error || "Something went wrong.");
        });
    }
    changedItem("promo", promoCodeChecked);
  }, []);

  useEffect(() => {
    function onProgressUpdate(data: number) {
      setProgress((prev) => {
        const prog = prev + 1;
        if (prog <= 99) {
          return prog;
        }
        return prev;
      });
    }

    function onProcessEnd() {
      setLoading(false);
      dispatch(setSelectFiles({ files: [], quickFiles: [] }));
      navigate("/creator/message-automatic");
    }

    socket.on("file-updated", onProcessEnd);
    socket.on("progress-update", onProgressUpdate);

    return () => {
      socket.off("file-updated", onProcessEnd);
      socket.off("progress-update", onProgressUpdate);
    };
  }, []);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress / 2);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  useEffect(() => {
    if (!automation.price || automation.price <= 0) {
      console.log(true);
      setPromoCodeChecked(false);
      delete automation.discount;
      delete automation.discountType;
      delete automation.promo;
      setPromoCurrency(false);
      setPromoPercentage(false);
      setDiscountCurrency(undefined);
      setDiscountPercentage(undefined);
    }
  }, [automation.price]);

  const changedItem = (field: keyof IMediapush, value: any) => {
    setAutomation((prevAutomation) => ({
      ...prevAutomation,
      [field]: value,
    }));
  };

  const handleCustomPriceChange = (value: number | null) => {
    if (value !== null) {
      setCustomPrice(value);
      setRadioValue(undefined);
      changedItem("price", value);
      if (value > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
      }
    }
  };

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setRadioValue(String(value));
    setCustomPrice(parseInt(value));
    changedItem("price", parseInt(value));
    if (value > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
    }
  };

  const handleDiscountPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);

    if (!isNaN(value)) {
      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
    } else {
      value = 0;
    }

    setPromoPercentage(true);
    setPromoCurrency(false);
    setDiscountPercentage(value.toString());
    setDiscountCurrency(undefined);
    changedItem("discount", value);
    changedItem("discountType", "PERCENTAGE");
  };

  const handleDiscountCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromoCurrency(true);
    setPromoPercentage(false);
    setDiscountCurrency(value);
    setDiscountPercentage(undefined);
    changedItem("discount", parseInt(value));
    changedItem("discountType", "CURRENCY");
  };

  const handleFilesChange = async (fileList: UploadFile<[]>[]) => {
    const newQuickFiles: any[] = [];
    const newFileList: MediaPushFiles[] = fileList?.map((file: any) => {
      const fileIndex = files.findIndex((currentFile) => {
        return currentFile.uid === file.uid;
      });
      if (file.imgType !== undefined && file.imgType === "quick-file") {
        newQuickFiles.push({ id: file.uid, isPrivate: fileIndex !== -1 ? files[fileIndex].isPrivate : true });
      }
      if (fileIndex !== -1) {
        return {
          ...file,
          isPrivate: files[fileIndex].isPrivate,
        };
      }
      return {
        ...file,
        isPrivate: fileIndex !== -1 ? files[fileIndex].isPrivate : true,
      };
    });

    const filesToSetPrivacy = differenceBy(fileList, files, "uid").filter((file) => file.status === "done");
    if (filesToSetPrivacy) {
      const filesTSP: PrivateType[] = await Promise.all(
        filesToSetPrivacy.map(async (file: any) => {
          let src = file.url as string;
          if (file.imgType !== "quick-file") {
            if (file.type?.includes("video")) {
              src = await generateThumbnailURL(file.originFileObj as RcFile);
            } else {
              src = await generateURL(file.originFileObj as RcFile);
            }
          } else {
            src = file.thumbUrl!;
          }
          return {
            showDialog: true,
            fileId: file.uid,
            fileSrc: src,
            fileType: file.type!,
          };
        })
      );
      setPrivateFiles((prev: PrivateType[]) => uniqBy([...prev, ...filesTSP], "fileId"));
    }

    dispatch(setSelectFiles({ files: newFileList, quickFiles: newQuickFiles }));
  };

  const onNextClick = (privateFile: PrivateType) => {
    setPrivateFiles((prev: PrivateType[]) => prev.filter((file) => file.fileId !== privateFile.fileId));
  };

  const handleLockSwitchChange = async (value: boolean, privateFile: PrivateType) => {
    const newQuickFileList: MediaPushFiles[] = quickFiles?.map((file: any) => {
      if (file.id === privateFile.fileId) {
        return {
          ...file,
          isPrivate: value,
        };
      }
      return file;
    });
    const newFileList: MediaPushFiles[] = files?.map((file) => {
      if (file.uid === privateFile.fileId) {
        return {
          ...file,
          isPrivate: value,
        };
      }
      return file;
    });
    dispatch(setSelectFiles({ files: newFileList, quickFiles: newQuickFileList }));
  };

  const processQuickFiles = (array: any[]): any[] => {
    let uniqueItems: any[] = [];
    array.forEach((item) => {
      if (typeof item === "object" && item.id) {
        const id: string = item.id;
        const isAlready = uniqueItems.find((items: any) => items.id === id);
        if (!isAlready) {
          uniqueItems.push(item);
        }
      } else {
        const isAlready = uniqueItems.find((items: any) => items === item);
        if (!isAlready) {
          uniqueItems.push(item);
        }
      }
    });
    return uniqueItems;
  };

  const handleUpdateAutomation = async () => {
    if (!automation?.text) {
      message.warning(t("text-required"));
      return;
    } else if (automation?.price !== undefined && automation?.price > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
      return;
    }
    setLoading(true);
    let mediaToCreate: IMediapush = {
      ...automation,
    };

    const uniqueQuickFiles = processQuickFiles(quickFiles);

    let formData: any = new FormData();
    const filesPrivacy: FilePrivacy[] = [];
    const videoFileToChunk: any[] = [];

    files?.forEach((file: any) => {
      const isObject = isPlainObject(file);
      if (isObject && file?.imgType !== "quick-file") {
        if (file?.originFileObj?.type?.includes("image/")) {
          formData.append(`files`, file.originFileObj as any);
          filesPrivacy.push({
            fileId: file.uid,
            isPrivate: file.isPrivate,
          });
        } else {
          videoFileToChunk.push(file);
        }
      }
    });
    formData.set("price", 0);
    for (const [key, value] of Object.entries(mediaToCreate)) {
      formData.set(key, value);
    }
    formData.set("fileIds", JSON.stringify(filesPrivacy));
    formData.set("quickFiles", JSON.stringify(uniqueQuickFiles));
    formData.set("type", block);
    if (videoFileToChunk.length > 0) {
      const videoFiles: any[] = [];
      const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
      setTotalSize(totalSizes);

      for (let i = 0; i < videoFileToChunk.length; i++) {
        const file = videoFileToChunk[i];

        const result = await storageService.uploadFileMultiPart(file, setLoadedData);

        videoFiles.push({
          Location: result.Location,
          isPrivate: file.isPrivate,
          Key: result.Key,
          ETag: result.ETag,
          Bucket: result.Bucket,
          filename: file.name,
          size: file.size,
        });
      }

      formData.append("videoFiles", JSON.stringify(videoFiles));
    }

    await automationService.updateAutomation(formData, setProgress).then((resp: any) => {
      setLoading(false);
      if (resp.error) {
        message.error(t("something-want-wrong"));
      } else {
        dispatch(setSelectFiles({ files: [], quickFiles: [] }));
        navigate("/creator/message-automatic");
      }
    });
  };

  function isPlainObject(value: any) {
    if (value === null || typeof value !== "object") {
      return false;
    }
    return Object.getPrototypeOf(value) === Object.prototype;
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    if (e.ctrlKey || e.metaKey || key === "Backspace" || key === "Tab" || key === "ArrowLeft" || key === "ArrowRight" || key === "Delete") {
      return;
    }

    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  };

  const handleRemoveFile = (fileId: string) => {
    setPrivateFiles((prev: any) => prev.filter((f: any) => f.fileId !== fileId));
    dispatch(setSelectFiles({ files: files.filter((f: any) => f.uid !== fileId), quickFiles: undefined }));
    setComponentKey((prevKey) => prevKey + 1);
    removeFileFromSelected(fileId);
  };
  const removeFileFromSelected = (fileId: string) => {
    const params = new URLSearchParams(location.search);
    const selectedParam = params.get("selected");

    // Parse the 'selected' parameter as an array of file IDs
    let selectedFiles: string[] = selectedParam ? JSON.parse(selectedParam) : [];

    // Filter out the fileId that needs to be removed
    selectedFiles = selectedFiles.filter((id) => id !== fileId);

    // Update the 'selected' parameter in the URL
    if (selectedFiles.length > 0) {
      params.set("selected", JSON.stringify(selectedFiles));
    } else {
      params.delete("selected"); // Remove the 'selected' parameter if no files are selected
    }

    // Update the URL without reloading the page
    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { replace: true }
    );
  };

  return (
    <Spin
      spinning={loading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout>
        <div style={{ display: privateFiles.length > 0 ? "none" : "" }}>
          <Layout.Content className="content CreatorAutomaticMessageEditPageWrapper gradient-background-blue-settings">
            <Row className="relative justify-content-center header-title-container-60">
              <ArrowLeftIconComponent
                left={-2}
                top={10}
                navigateTo="/creator/message-automatic"
                callback={() => {
                  dispatch(setSelectFiles({ files: [], quickFiles: [] }));
                }}
              />
              <Typography.Text className="header-title-black-35">{t("automation")}</Typography.Text>
            </Row>
            <Space className="w-full gap-21" direction="vertical">
              <Row>
                <Row className="w-full justify-content-center mt-20 mb-20">
                  <Typography.Text className="font-13-regular text-grey-color mb-3">{t("send-file-description")}</Typography.Text>
                </Row>
                <AutomationMediaComponent
                  key={componentKey}
                  maxCount={MAX_MEDIAS_TO_SEND}
                  onFilesChange={handleFilesChange}
                  defaultList={defaultList}
                  allFiles={files}
                  currentModule={"automation"}
                />
              </Row>
              <Row>
                <MessageTextAreaComponent
                  value={automation.text}
                  handleChange={(value) => {
                    changedItem("text", value.target.value);
                  }}
                />
              </Row>
              <Row>
                <Space className="w-full gap-10" direction="vertical">
                  <PriceInputComponent customPrice={customPrice} handleCustomPriceChange={handleCustomPriceChange} />
                  <Radio.Group className="w-full mt-10" value={radioValue} onChange={handleRadioChange}>
                    <Row gutter={10}>
                      {mediaAmounts.map((amount, index) => {
                        return (
                          <Col className="mt-3" key={index} span={6}>
                            <Radio.Button className="mediaAmountRadioButton mediaPush" value={amount.value}>
                              {amount.label}
                            </Radio.Button>
                          </Col>
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </Space>
              </Row>
              <Row className="justify-content-between" align="middle">
                <Typography.Text className="font-20-bold text-white-color">{t("add-a-promotion")}</Typography.Text>
                <Switch
                  disabled={automation?.price === undefined || automation?.price <= 0 || Number.isNaN(automation.price)}
                  className="switch mediaPushSwitch"
                  defaultChecked={promoCodeChecked}
                  checked={promoCodeChecked}
                  onChange={(checked) => {
                    setPromoCodeChecked(checked);
                    changedItem("promo", checked);
                    if (!checked) {
                      delete automation.discount;
                      delete automation.discountType;
                      delete automation.expireTimeType;
                      setPromoCurrency(false);
                      setPromoPercentage(false);
                      setDiscountCurrency(undefined);
                      setDiscountPercentage(undefined);
                    }
                  }}
                />
              </Row>
              {promoCodeChecked && (
                <Row className="justify-content-between mt-16">
                  <Space className="w-full gap-10" direction="vertical">
                    <Typography.Text className="font-30-bold text-white-color">{t("discount")}</Typography.Text>
                    <Row>
                      <Col span={8}>
                        <InputComponent
                          className={`${promoCurrency ? "mediaPushMediumGreenInput" : "mediaPushDarkInput"}`}
                          suffix="€"
                          onChange={handleDiscountCurrencyChange}
                          onKeyDown={handleKeyDown}
                          value={discountCurrency}
                          inputMode="numeric"
                        />
                      </Col>
                      <Col className="d-flex items-center justify-content-center" span={8}>
                        <Typography.Text className="font-13-bold text-grey-color">{t("auth-signUp-divider")}</Typography.Text>
                      </Col>
                      <Col span={8}>
                        <InputComponent
                          className={`${promoPercentage ? "mediaPushMediumGreenInput" : "mediaPushDarkInput"}`}
                          suffix="%"
                          onChange={handleDiscountPercentageChange}
                          onKeyDown={handleKeyDown}
                          value={discountPercentage}
                          inputMode="numeric"
                        />
                      </Col>
                    </Row>
                  </Space>
                </Row>
              )}
            </Space>
            <StickyButtonComponent
              title={t("save")}
              handleClickButton={handleUpdateAutomation}
              disabled={
                !automation.text ||
                (promoCodeChecked &&
                  (discountCurrency === undefined || discountCurrency === null || discountCurrency === "") &&
                  (discountPercentage === undefined || discountPercentage === null || discountPercentage === ""))
              }
            />
          </Layout.Content>
        </div>
        {privateFiles.length > 0 &&
          privateFiles.map((privateFile) => (
            <Layout.Content key={privateFile.fileId} className="content creatorMediaPushContentWrapper gradient-background">
              <Row className="relative justify-content-center header-title-container-60">
                <img
                  className="header-arrow-left-media"
                  style={{ top: 10 }}
                  src={ArrowLeftIcon}
                  alt="arrow left"
                  onClick={() => handleRemoveFile(privateFile.fileId)}
                />
                <Typography.Text className="header-title-black-32">{t("automation")}</Typography.Text>
              </Row>
              <Row className="w-full justify-content-center">
                <img className="images-design" src={privateFile.fileSrc} alt="Loly" />
              </Row>
              {/* )} */}
              <Space className="w-full gap-6" direction="vertical">
                <Row className="blur-media-row">
                  <Row className="w-full justify-content-between">
                    <Typography.Text className="font-24-bold text-white-color">{t("want-blur-media")}</Typography.Text>
                    <Switch className="switch revenueSwitch" defaultChecked={true} onChange={(value) => handleLockSwitchChange(value, privateFile)} />
                  </Row>
                  <Row className="w-full padding-y justify-content-center mt-40">
                    <AuthButtonComponent height="65px" fontSize="20px" label={t("next")} onClick={() => onNextClick(privateFile)} />
                  </Row>
                </Row>
              </Space>
            </Layout.Content>
          ))}
      </Layout>
    </Spin>
  );
};

export default CreatorAutomaticMessageEditPage;
