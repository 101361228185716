import { Col, Layout, Row, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FanHomeSuggestionComponent from "../../../components/FanHomeSuggestionComponent";
import FooterComponent from "../../../components/FooterComponent";
import SearchComponent from "../../../components/SearchComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import SuggestionCardComponent from "../../../components/SuggestionCardComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import { AppDispatch, StoreState } from "../../../redux/store";
import { resetList, search, setCurrentCategory } from "../../../redux/users/usersSlice";
import RecentVisitedProfile from "../RecentVisitedProfile";
import DirectLinksComponent from "../../../components/DirectLinksComponent";
import "./index.less";

const NB_USER_TO_SHOW = 10;

const SearchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  let { type, categoryName } = useParams();

  const { list: creators, total, loading, currentCategory } = useSelector((state: StoreState) => state.users);

  const shouldFetch = useRef(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchValue, setSearchValue] = useState("");
  const [seeAllResults, setSeeAllResults] = useState<boolean>(false);
  const [showRecentVisitedProfile, setShowRecentVisitedProfile] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchUsersByCategory();
    }

    document.addEventListener("click", handleClickOutside);
    ScrollToTop();
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!showRecentVisitedProfile && searchValue === "") {
      fetchUsersByCategory();
    }
  }, [showRecentVisitedProfile]);

  const handleInputClick = () => {
    setShowRecentVisitedProfile(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setShowRecentVisitedProfile(false);
    }
  };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const fetchUsersByCategory = () => {
    setSeeAllResults(false);
    setPage(1);
    setHasNextPage(true);
    /* if (categoryName?.includes("-")) {
      categoryName = categoryName?.replace("-", "/");
    } */
    if (categoryName?.includes("+")) {
      categoryName = categoryName?.replace("+", "%2B");
    }
    const payload = {
      category: categoryName,
      limit: NB_USER_TO_SHOW,
    };
    if (currentCategory !== categoryName) {
      dispatch(setCurrentCategory(categoryName));
      dispatch(search(payload));
    }
  };

  const searchCreators = (value: string) => {
    if (value !== undefined && value !== "") {
      setSearchValue(value);
      setSeeAllResults(false);
      setPage(1);
      setHasNextPage(true);
      if (categoryName?.includes("-")) {
        categoryName = categoryName?.replace("-", "/");
      }
      if (categoryName?.includes("+")) {
        categoryName = categoryName?.replace("+", "%2B");
      }
      const payload = {
        q: value,
        category: categoryName,
        limit: NB_USER_TO_SHOW,
      };
      dispatch(search(payload));
    } else {
      cancelSearching();
    }
  };

  const cancelSearching = () => {
    setSearchValue("");
    setSeeAllResults(false);
    setHasNextPage(false);
    setPage(1);
    dispatch(resetList());
  };

  const onProfileClick = (username: string) => {
    navigate(`/p/${username}`);
  };

  function loadMoreUsers() {
    const nextPage = page + 1;
    const totalPages = Math.ceil(total! / NB_USER_TO_SHOW);
    if (categoryName?.includes("-")) {
      categoryName = categoryName?.replace("-", "/");
    }
    if (categoryName?.includes("+")) {
      categoryName = categoryName?.replace("+", "%2B");
    }
    const payload = {
      q: searchValue,
      limit: NB_USER_TO_SHOW,
      offset: page * NB_USER_TO_SHOW,
      category: categoryName,
    };
    dispatch(search(payload));
    if (nextPage >= totalPages) {
      setHasNextPage(false);
    }
    setPage(nextPage);
  }

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  return (
    <Layout>
      <Layout.Content className="content fanSearchContentWrapper background-category no-scroll-horizontal">
        <Row className="relative justify-content-center header-title-container-80">
          <ArrowLeftIconComponent left={0} top={7} />
          <Typography.Text className="header-title-black-35 custom-margin-title">{t("search")}</Typography.Text>
        </Row>
        <Row className="items-center mt-5 pr-21">
          <Col span={searchValue ? 19 : 24} ref={inputRef}>
            <SearchComponent
              onClick={handleInputClick}
              placeholder={`${t("search-2")}`}
              value={searchValue}
              handleChange={(e) => searchCreators(e.target.value)}
            />
          </Col>
          <Col span={5} className={`text-end ${searchValue ? "" : "d-none"} `}>
            <Typography.Text className="cancel-button cursor-pointer" onClick={cancelSearching}>
              {t("cancel")}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="direct-links-wrapper">
          <DirectLinksComponent />
        </Row>
        {showRecentVisitedProfile && !searchValue ? (
          <RecentVisitedProfile />
        ) : !showRecentVisitedProfile && !searchValue ? (
          <div className="pr-21">
            <Row>
              <Typography.Text className="content-title mt-30">{t("suggestions")}</Typography.Text>
            </Row>
            <Row gutter={[13, 10]}>
              {creators.length !== 0
                ? creators.map((creator, index) => {
                    return (
                      <Col className="mt-20" key={index} span={12}>
                        <SuggestionCardComponent
                          suggestions={true}
                          name={creator.name ? creator.name : ""}
                          username={creator.username ? creator.username : ""}
                          avatar={creator.avatar ? creator.avatar : ""}
                          lastName={creator.lastName ? creator.lastName : ""}
                          firstName={creator.firstName ? creator.firstName : ""}
                          handleClick={() => onProfileClick(creator.username ? creator.username : "")}
                        />
                      </Col>
                    );
                  })
                : !loading && (
                    <>
                      <Row className="w-full justify-content-center mt-30 mb-20">
                        <Typography.Text className="no-creator-text">{t("no-creator-category")}</Typography.Text>
                      </Row>
                      <FanHomeSuggestionComponent showSection4={false} />
                    </>
                  )}
            </Row>
            {creators && !seeAllResults && total! > NB_USER_TO_SHOW ? (
              <Row className={`mt-58 mx-auto ${seeAllResults ? "d-none" : ""}`}>
                <Typography.Text
                  onClick={() => {
                    setSeeAllResults(true);
                    setHasNextPage(true);
                  }}
                  className="SearchSeeAllResults mx-auto"
                >
                  {t(`see-all-results`)}
                </Typography.Text>
              </Row>
            ) : null}

            {(loading || hasNextPage) && seeAllResults && <SpinnerComponent refProp={sentryRef} />}
          </div>
        ) : (
          <>
            <Row className="mt-5">
              {creators &&
                creators.map((creator, index) => {
                  return (index < NB_USER_TO_SHOW && !seeAllResults) || seeAllResults ? (
                    <Col key={index} span={24}>
                      <SuggestionCardComponent
                        suggestions={false}
                        name={creator.name ? creator.name : ""}
                        username={creator.username ? creator.username : ""}
                        firstName={creator.firstName ? creator.firstName : ""}
                        avatar={creator.avatar ? creator.avatar : ""}
                        lastName={creator.lastName ? creator.lastName : ""}
                        handleClick={() => onProfileClick(creator.username ? creator.username : "")}
                      />
                    </Col>
                  ) : null;
                })}
            </Row>
            {creators && creators.length !== 0 && !seeAllResults && total! > NB_USER_TO_SHOW ? (
              <Row className={`mt-16 mx-auto ${seeAllResults ? "d-none" : ""}`}>
                <Typography.Text
                  onClick={() => {
                    setSeeAllResults(true);
                    setHasNextPage(true);
                  }}
                  className="SearchSeeAllResults mx-auto"
                >
                  {t("see-all-results")}
                </Typography.Text>
              </Row>
            ) : null}

            {(loading || hasNextPage) && seeAllResults && <SpinnerComponent refProp={sentryRef} />}
          </>
        )}
      </Layout.Content>
      <FooterComponent type={type || ""} page={FOOTER_IDS.SEARCH} />
    </Layout>
  );
};

export default SearchPage;
