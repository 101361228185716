import type { TabsProps } from "antd";
import { Layout, Row, Tabs, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import CreatorPaymentHistoryPaymentTabComponent from "../../../components/CreatorPaymentHisotryPaymentTabComponent";
import CreatorPaymentHistoryMonthlyReportTabComponent from "../../../components/CreatorPaymentHistoryMonthlyReportTabComponent";
import { IPayoutRequest } from "../../../helpers/types";
import { payoutRequestService } from "../../../services/payoutRequest.service";
import useInfiniteScroll from "react-infinite-scroll-hook";
import "./index.less";

const CreatorPaymentHistoryPage = () => {
  const limit = 20;
  const { t } = useTranslation();
  const [payoutRequests, setPayoutRequests] = useState<IPayoutRequest[]>([]);
  const [offset, setOffSet] = useState<any>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadMoreData();
  }, []);

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await payoutRequestService.getPayoutHistory({ limit, offset });
      if (response.status === 0) {
        let data = [...payoutRequests, ...response.data.payment];
        setLoading(false);
        setPayoutRequests(data);
        setOffSet(data.length);
        if (data.length >= response.data.totalPayments) {
          setHasMore(false);
          setLoading(false);
        }
      }
    } catch (error) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreData,
  });

  return (
    <Layout>
      <Layout.Content className="content creatorPaymentHistoryContentWrapper gradient-background-black-blue">
        <Row className="relative justify-content-center header-title-container">
          <ArrowLeftIconComponent left={0} top={0} />
          <Typography.Text className="header-title-black-20">{t("payments-history")}</Typography.Text>
        </Row>
        <CreatorPaymentHistoryPaymentTabComponent list={payoutRequests} sentryRef={sentryRef} hasMore={hasMore} loading={loading} />
      </Layout.Content>
    </Layout>
  );
};

export default CreatorPaymentHistoryPage;
