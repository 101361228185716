import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import NoPublicationIcon from "../../assets/icons/no-publication.svg";
import "./index.less";

const NoPublicationComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="no-publication-wrapper">
      <img src={NoPublicationIcon} className="no-publication-img" alt="No publication" />
      <Typography.Text className="no-publication-text">{t("no-publication")}</Typography.Text>
    </div>
  );
};

export default NoPublicationComponent;
