import React, { Suspense } from "react";
import { FORMATION_CONFIG } from "../../../../helpers/formationConfig";
import SpinnerComponent from "../../../SpinnerComponent/SpinnerComponent";
import { useTranslation } from "react-i18next";
import "./index.less";

interface FormationModalProps {
  visible: boolean;
  onClose: () => void;
  getIt: () => void;
  id: keyof typeof FORMATION_CONFIG;
}

const FormationModalComponent: React.FC<FormationModalProps> = ({ visible, onClose, getIt, id }) => {
  const { t } = useTranslation();

  const config = FORMATION_CONFIG[id];
  if (!visible) return null;

  const { component: Component } = config;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="formation-modal-overlay no-scroll" onClick={handleOverlayClick}>
      <div className="formation-modal-content no-scroll-horizontal" onClick={handleModalClick}>
        <Suspense
          fallback={
            <div className="w-full d-flex justify-content-center">
              <SpinnerComponent />
            </div>
          }
        >
          <Component fromModal={true} />
          <div className="sticky-button-container">
            <button className="sticky-button" onClick={getIt}>
              {t("i-understand")}
            </button>
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default FormationModalComponent;
