import { lazy } from "react";

export type FormationComponentProps = { fromModal?: boolean };

export const FORMATION_CONFIG: Record<
  string,
  { title: string; component: React.LazyExoticComponent<React.FC<FormationComponentProps>> }
> = {
  "training-video": {
    title: "a-z-tutorial",
    component: lazy(() => import("../components/Formations/Creators/TrainingVideoComponent")),
  },
  "media-push": {
    title: "tutorial-media-push",
    component: lazy(() => import("../components/Formations/Creators/MediaPushFormationComponent/MediaPushFormationComponent")),
  },
  collection: {
    title: "tutorial-collections",
    component: lazy(() => import("../components/Formations/Creators/CollectionFormationComponent")),
  },
  chat: {
    title: "tutorial-messaging",
    component: lazy(() => import("../components/Formations/Creators/ChatFormationComponent")),
  },
  live: {
    title: "tutorial-lives",
    component: lazy(() => import("../components/Formations/Creators/LiveFormationComponent")),
  },
  unlock: {
    title: "unlocks",
    component: lazy(() => import("../components/Formations/Creators/UnlockFormationComponent")),
  },
  "profile-first-modal": {
    title: "",
    component: lazy(() => import("../components/Formations/Creators/ProfileFirstModalFormationComponent")),
  },
  profile: {
    title: "tutorial-profile",
    component: lazy(() => import("../components/Formations/Creators/ProfileFormationComponent")),
  },
  search: {
    title: "tutorial-search",
    component: lazy(() => import("../components/Formations/Creators/SearchFormationComponent")),
  },
  "presentation-videos": {
    title: "tutorial-presentation-videos",
    component: lazy(() => import("../components/Formations/Creators/PresentationVideosComponent")),
  },
  payment: {
    title: "payments",
    component: lazy(() => import("../components/Formations/Creators/PaymentFormationComponent")),
  },
  "generate-traffic": {
    title: "generate-traffic",
    component: lazy(() => import("../components/Formations/Creators/GenerateTrafficFormationComponent")),
  },
  "quick-file": {
    title: "quick-file",
    component: lazy(() => import("../components/Formations/Creators/QuickFileFormationComponent")),
  },
};
